












































































































import Vue from "vue";
import Component from "vue-class-component";
// import ExhibitorResultCard from "@/components/shared/ExhibitorResultCard.vue";
import { getModule } from "vuex-module-decorators";
import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import UpcomingStreams from "@/components/exhibitor-profile/UpcomingStreams.vue";

const tradeshowStore = getModule(tradeshowVuexModule);

@Component({
    components: {
        // ExhibitorResultCard,
        Spinners,
        PageTitleHeader,
        UpcomingStreams
    }
})
export default class TradeshowSwimlaneWithDifferentLogoSizes extends Vue {
    isLoading = true;

    get pageConfig() {
        return this.$store.getters.getPageOptions("tradeshow") || {};
    }

    get textHeader() {
        return this.pageConfig?.textHeader;
    }

    get isUsedBy() {
        return this.$router.currentRoute.name;
    }

    get tradeshowCompanies() {
        const companies = tradeshowStore.allTradeshowCompanies;

        return companies.sort((cur: any, nxt: any) => {
            if (cur.companyName) {
                return cur.companyName.localeCompare(nxt.companyName);
            } else return cur.name.localeCompare(nxt.name);
        });
    }

    get cssVariables() {
        return this.$store.getters.cssVariables;
    }

    get homeLabel() {
        return this.$store.getters.homeLabel;
    }

    get headerAlignment(): string {
        return this.$store.getters.getPageOptions("home").section
            .featuredSolutionProvider.labelHeaderAlignment
            ? "text-center"
            : "";
    }

    get pageDecorImage(): string {
        let returnValue = "";
        if (
            this.pageConfig.decorImages &&
            this.pageConfig.decorImages.pageBackground
        ) {
            returnValue = `/bg/${this.pageConfig.decorImages.pageBackground}`;
        }
        return returnValue;
    }

    created() {
        this.logPageVisit();

        tradeshowStore.getTradeshowCompanies().finally(() => {
            this.isLoading = false;
        });
    }

    filterCompanyFromConfig(category: string) {
        if (category) {
            return this.tradeshowCompanies.filter((item: any) => {
                return item.level === category;
            });
        } else {
            return this.tradeshowCompanies;
        }
    }
    getLogoImage(exhibitor: any) {
        let returnPath = exhibitor.imageLogo || exhibitor.images?.logo;
        if (!returnPath) {
            return (returnPath = `/bg/${this.pageConfig.defaultLogo}`);
        } else {
            return returnPath;
        }
    }

    logPageVisit() {
        const logData = {
            type: `SponsorLanding`
        };

        this.$store
            .dispatch("appendLogEntry", logData)
            .catch((err) => console.log(err));
    }
}
